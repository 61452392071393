// require('dotenv').config({ path: `.env` })
const airtableTables = require('./airtableTables.js')

module.exports = {
  siteMetadata: {
    title: `Veronikas Atelier`,
    description: `Veronikas Atelier`,
    author: `elaxart`,
    twitter: '',
    adsense: '',
    siteUrl: 'https://veronikabogusch.com',
  },
  plugins: [
    'gatsby-plugin-sitemap',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Veronikas Atelier`,
        short_name: `Veronikas Atelier`,
        start_url: `/`,
        background_color: '#128374',
        theme_color: '#ad9961',
        display: `standalone`,
        icon: `src/assets/images/va_logo_512.png`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: `gatsby-source-airtable`,
      options: {
        apiKey: 'patq1dgIrIoQbpOc7.688dc67d8c2b0728f52debc39d35bee4d40be23c4da062c841eec368a58d4946', // may instead specify via env, see below
        concurrency: 5, // default, see using markdown and attachments for more information
        tables: airtableTables,
      },
    },
    {
      resolve: `gatsby-plugin-portal`,
      options: {
        key: 'portal',
        id: 'portal',
      },
    },
    {
      resolve: `gatsby-plugin-purgecss`,
      options: {
        printRejected: true, // Print removed selectors and processed file names
        develop: false, // Enable while using `gatsby develop`
        // tailwind: true, // Enable tailwindcss support
        // whitelist: ['whitelist'], // Don't remove this selector
        // ignore: ['/ignored.css', 'prismjs/', 'docsearch.js/'], // Ignore files/folders
        purgeOnly: [
          // Purge only these files/folders
          'scss/gatstrap.scss',
          // 'node_modules/bootstrap',
        ],
      },
    },

    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /assets\/svg/,
        },
      },
    },

    // `gatsby-plugin-eslint`,
    `gatsby-plugin-react-helmet`,
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
    // 'gatsby-plugin-catch-links',
    `gatsby-plugin-smoothscroll`,
    'gatsby-plugin-sass',
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    `gatsby-transformer-remark`,
    'gatsby-plugin-typescript',
  ],
}
