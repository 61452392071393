const baseName = 'appOGqS3OVg61ytuq' // process.env.AIRTABLE_BASE_NAME

module.exports = [
  {
    baseId: baseName,
    tableName: `Artworks`,
    tableView: `List view`, // optional
    queryName: `Artworks`, // optionally default is false - makes all records in this table a separate node type, based on your tableView, or if not present, tableName, e.g. a table called "Fruit" would become "allAirtableArtworksFruit". Useful when pulling many airtables with similar structures or fields that have different types. See https://github.com/jbolda/gatsby-source-airtable/pull/52.
    mapping: { Images: `fileNode` }, // optional, e.g. "text/markdown", "fileNode"
    // tableLinks: [`CASE`, `SENSITIVE`, `COLUMN`, `NAMES`], // optional, for deep linking to records across tables.
    separateNodeType: true, // boolean, default is false, see the documentation on naming conflicts for more information
    // separateMapType: false, // boolean, default is false, see the documentation on using markdown and attachments for more information
    // defaultValues: {
    //   // perform your conditional logic on name_of_field.length > 0 ? condition_1 : condition_2
    //   Sold: false,
    // },
  },
  {
    baseId: baseName,
    tableName: `Inspirations`,
    queryName: `Inspirations`,
    tableView: `Grid view`,
    mapping: { Image: `fileNode` },
    separateNodeType: true,
  },
  {
    baseId: baseName,
    tableName: `Moods`,
    queryName: `Moods`,
    tableView: `Grid view`,
    separateNodeType: true,
  },
  {
    baseId: baseName,
    tableName: `Prices`,
    queryName: `Prices`,
    tableView: `Grid view`,
    separateNodeType: true,
  },
  {
    baseId: baseName,
    tableName: `Texts`,
    queryName: `Texts`,
    tableView: `Grid view`,
    mapping: { Text: 'text/markdown' },
    separateNodeType: true,
  },
  {
    baseId: baseName,
    tableName: `Images`,
    queryName: `Images`,
    tableView: `Grid view`,
    mapping: { Image: `fileNode` },
    separateNodeType: true,
  },
]
