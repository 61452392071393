import React from 'react'

import './PageSection.scss'


type Props = React.PropsWithChildren<{
  id?: string
  className?: string
  dark?: boolean
}>

const PageSection = ({className = '', dark = false, children, ...restProps}: Props) => {
  return (
    <section
      className={`${dark ? 'page-section-dark' : 'page-section'} ${className}`}
      {...restProps}
    >
      <div className="container">{children}</div>
    </section>
  )
}

export default PageSection
