import React from 'react'
import { PageProps } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'

import Layout from '../components/Layout'

import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Meta from '../components/Meta'

const Widerrufsbelehrung: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Widerrufsbelehrung" />
      <PageSection>
        <SectionHeaderRow headline="Widerrufsbelehrung" />
        <div className="row">
          <div className="col-12">
            <p>
              <span>
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
                Gr&uuml;nden diesen Vertrag zu widerrufen. Die Widerrufsfrist
                betr&auml;gt vierzehn Tage ab dem Tag, an dem Sie oder ein von
                Ihnen benannter Dritter, der nicht Bef&ouml;rderer ist, die
                Waren in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht
                auszu&uuml;ben, m&uuml;ssen Sie uns:
              </span>
            </p>

            <p>Mustafa Nemat Ali</p>

            <p>L&uuml;becker Str. 37</p>

            <p>19053 Schwerin</p>

            <p>contact@veronikabogusch.com</p>

            <p>
              <span>
                mittels einer eindeutigen Erkl&auml;rung (z.B. ein mit der Post
                versandter Brief oder E-Mail) &uuml;ber Ihren Entschluss, diesen
                Vertrag zu widerrufen, informieren. Sie k&ouml;nnen daf&uuml;r
                das beigef&uuml;gte Muster-Widerrufsformular verwenden, das
                jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist
                reicht es aus, dass Sie die Mitteilung &uuml;ber die
                Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist
                absenden.
              </span>
            </p>

            <p>Folgen des Widerrufs</p>

            <p>
              <span>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                Zahlungen, die wir von Ihnen erhalten haben,
                einschlie&szlig;lich der Lieferkosten (mit Ausnahme der
                zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine
                andere Art der Lieferung als die von uns angebotene,
                g&uuml;nstigste Standardlieferung gew&auml;hlt haben),
                unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab
                dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber
                Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r
                diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel,
                das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt
                haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas
                anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
                R&uuml;ckzahlung Entgelte berechnet.
              </span>
            </p>

            <p>
              <span>
                Wir k&ouml;nnen die R&uuml;ckzahlung verweigern, bis wir die
                Waren wieder zur&uuml;ckerhalten haben oder bis Sie den Nachweis
                erbracht haben, dass Sie die Waren zur&uuml;ckgesandt haben, je
                nachdem, welches der fr&uuml;here Zeitpunkt ist. Sie haben die
                Waren unverz&uuml;glich und in jedem Fall sp&auml;testens binnen
                vierzehn Tagen ab dem Tag, an dem Sie uns &uuml;ber den Widerruf
                dieses Vertrags unterrichten, an uns zur&uuml;ckzusenden oder zu
                &uuml;bergeben. Die Frist ist gewahrt, wenn Sie die Waren vor
                Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die
                unmittelbaren Kosten der R&uuml;cksendung der Waren.
              </span>
            </p>

            <p>
              <span>
                Sie m&uuml;ssen f&uuml;r einen etwaigen Wertverlust der Waren
                nur aufkommen, wenn dieser Wertverlust auf einen zur
                Pr&uuml;fung der Beschaffenheit, Eigenschaften und
                Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
                zur&uuml;ckzuf&uuml;hren ist.
              </span>
            </p>

            <p>Muster Widerrufsformular</p>
            <p>
              <span>
                Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie
                bitte dieses Formular aus und senden Sie es zur&uuml;ck.
              </span>
            </p>

            <p>An</p>

            <p>Mustafa Nemat Ali</p>

            <p>L&uuml;becker Str. 37</p>

            <p>19053 Schwerin</p>

            <p>contact@veronikabogusch.com</p>

            <p>
              <span>
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                abgeschlossenen Vertrag &uuml;ber den Kauf der folgenden Waren
                (*)/die Erbringung der folgenden Dienstleistung (*)
              </span>
            </p>

            <p>
              <span>Bestellt am (*)/erhalten am (*) _____________________</span>
            </p>

            <p>Name des/der Verbraucher(s)</p>

            <p>Anschrift des/der Verbraucher(s)</p>

            <p>
              <span>
                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
                Papier)
              </span>
            </p>

            <p>Datum</p>

            <p>(*) Unzutreffendes streichen.</p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Widerrufsbelehrung
