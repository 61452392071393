import React from 'react'
import nl2br from '../hooks/nl2br'
import './SectionHeaderRow.scss'

const SectionHeaderRow = ({
  headline,
  entryTextHtml,
  className,
  ...restProps
}: {
  headline: string
  entryTextHtml?: string
  className?: string
}): JSX.Element => {
  return (
    <div className="row justify-content-center">
      <div
        className={`col-lg-10 section-header text-center mb-4 ${className}`}
        {...restProps}
      >
        <h1 className="section-headline">{headline}</h1>
        {entryTextHtml && (
          <div dangerouslySetInnerHTML={{ __html: nl2br(entryTextHtml) }} />
        )}
      </div>
    </div>
  )
}

export default SectionHeaderRow
